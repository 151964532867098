.largelogo-3SAT {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -0px -0px;
  width: 72px;
  height: 26px;
}

.largelogo-A_1, .largelogo-svt1-hd, .largelogo-svt1 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -82px -0px;
  width: 40px;
  height: 18px;
}

.largelogo-A_1001 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -132px -0px;
  width: 45px;
  height: 11px;
}

.largelogo-A_1002 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -187px -0px;
  width: 45px;
  height: 10px;
}

.largelogo-A_1003,.largelogo-tv3-hd, .largelogo-tv3 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -242px -0px;
  width: 12px;
  height: 26px;
}

.largelogo-A_102 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -264px -0px;
  width: 34px;
  height: 26px;
}

.largelogo-A_1032, .largelogo-v-film-premiere-hd, .largelogo-v-film-premiere {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -308px -0px;
  width: 39px;
  height: 26px;
}

.largelogo-A_1036, .largelogo-eurosport-1-hd, .largelogo-eurosport-1 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -357px -0px;
  width: 47px;
  height: 24px;
}

.largelogo-A_105 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -414px -0px;
  width: 35px;
  height: 26px;
}

.largelogo-A_107 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -459px -0px;
  width: 47px;
  height: 12px;
}

.largelogo-A_109 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -516px -0px;
  width: 26px;
  height: 26px;
}

.largelogo-A_110 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -552px -0px;
  width: 47px;
  height: 15px;
}

.largelogo-A_111 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -609px -0px;
  width: 29px;
  height: 26px;
}

.largelogo-A_114 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -648px -0px;
  width: 47px;
  height: 17px;
}

.largelogo-A_115 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -705px -0px;
  width: 20px;
  height: 26px;
}

.largelogo-A_116 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -735px -0px;
  width: 28px;
  height: 26px;
}

.largelogo-A_117 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -773px -0px;
  width: 26px;
  height: 26px;
}

.largelogo-A_1198, .largelogo-v-sport-motor-hd, .largelogo-v-sport-motor {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -809px -0px;
  width: 48px;
  height: 23px;
}

.largelogo-A_120 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -867px -0px;
  width: 47px;
  height: 20px;
}

.largelogo-A_1207, .largelogo-v-film-action-hd, .largelogo-v-film-action {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -924px -0px;
  width: 39px;
  height: 26px;
}

.largelogo-A_1209 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -973px -0px;
  width: 39px;
  height: 26px;
}

.largelogo-A_121 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -187px -20px;
  width: 38px;
  height: 26px;
}

.largelogo-A_1210, .largelogo-v-film-family-hd, .largelogo-v-film-family {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -132px -21px;
  width: 39px;
  height: 26px;
}

.largelogo-A_1256 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -809px -33px;
  width: 47px;
  height: 18px;
}

.largelogo-A_129 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -866px -33px;
  width: 47px;
  height: 14px;
}

.largelogo-A_1303, .largelogo-history-hd, .largelogo-history {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -459px -22px;
  width: 37px;
  height: 26px;
}

.largelogo-A_131 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -0px -36px;
  width: 47px;
  height: 21px;
}

.largelogo-A_132 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -552px -25px;
  width: 20px;
  height: 26px;
}

.largelogo-A_133 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -57px -36px;
  width: 47px;
  height: 20px;
}

.largelogo-A_1334, .largelogo-v-series-hd, .largelogo-v-series {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -648px -27px;
  width: 39px;
  height: 26px;
}

.largelogo-A_135 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -357px -34px;
  width: 28px;
  height: 26px;
}

.largelogo-A_136 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -235px -36px;
  width: 48px;
  height: 26px;
}

.largelogo-A_141 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -293px -36px;
  width: 47px;
  height: 15px;
}

.largelogo-A_145 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -395px -36px;
  width: 26px;
  height: 26px;
}

.largelogo-A_148 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -506px -36px;
  width: 26px;
  height: 26px;
}

.largelogo-A_15,
.largelogo-discovery-channel {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -582px -36px;
  width: 20px;
  height: 26px;
}

.largelogo-A_155 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -612px -36px;
  width: 19px;
  height: 26px;
}

.largelogo-A_156 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -697px -36px;
  width: 38px;
  height: 26px;
}

.largelogo-A_157 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -745px -36px;
  width: 18px;
  height: 26px;
}

.largelogo-A_158 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -923px -36px;
  width: 47px;
  height: 23px;
}

.largelogo-A_16 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -114px -57px;
  width: 47px;
  height: 14px;
}

.largelogo-A_161 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -181px -56px;
  width: 35px;
  height: 26px;
}

.largelogo-A_167 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -866px -57px;
  width: 35px;
  height: 26px;
}

.largelogo-A_169 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -431px -58px;
  width: 47px;
  height: 20px;
}

.largelogo-A_17,
.largelogo-mtv {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -293px -61px;
  width: 40px;
  height: 26px;
}

.largelogo-A_170 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -980px -36px;
  width: 26px;
  height: 26px;
}

.largelogo-A_171 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -773px -61px;
  width: 35px;
  height: 26px;
}

.largelogo-A_172 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -0px -67px;
  width: 47px;
  height: 19px;
}

.largelogo-A_175 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -641px -63px;
  width: 45px;
  height: 8px;
}

.largelogo-A_177 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -542px -61px;
  width: 26px;
  height: 26px;
}

.largelogo-A_18 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -911px -69px;
  width: 47px;
  height: 11px;
}

.largelogo-A_182 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -818px -61px;
  width: 33px;
  height: 26px;
}

.largelogo-A_189 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -57px -66px;
  width: 26px;
  height: 26px;
}

.largelogo-A_19 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -343px -70px;
  width: 27px;
  height: 26px;
}

.largelogo-A_190 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -226px -72px;
  width: 45px;
  height: 26px;
}

.largelogo-A_193 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -380px -72px;
  width: 37px;
  height: 26px;
}

.largelogo-A_196 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -578px -72px;
  width: 47px;
  height: 16px;
}

.largelogo-A_197 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -488px -72px;
  width: 26px;
  height: 26px;
}

.largelogo-A_198 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -696px -72px;
  width: 26px;
  height: 26px;
}

.largelogo-A_199 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -732px -72px;
  width: 26px;
  height: 26px;
}

.largelogo-A_2, .largelogo-svt2-hd, .largelogo-svt2 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -968px -72px;
  width: 45px;
  height: 18px;
}

.largelogo-A_20 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -93px -81px;
  width: 26px;
  height: 26px;
}

.largelogo-A_200 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -129px -81px;
  width: 33px;
  height: 26px;
}

.largelogo-A_202 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -635px -81px;
  width: 26px;
  height: 26px;
}

.largelogo-A_203 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -427px -88px;
  width: 47px;
  height: 20px;
}

.largelogo-A_207 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -911px -90px;
  width: 27px;
  height: 26px;
}

.largelogo-A_208 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -172px -92px;
  width: 27px;
  height: 26px;
}

.largelogo-A_209 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -861px -93px;
  width: 27px;
  height: 26px;
}

.largelogo-A_21 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -0px -96px;
  width: 41px;
  height: 26px;
}

.largelogo-A_210 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -281px -97px;
  width: 26px;
  height: 26px;
}

.largelogo-A_213,
.largelogo-kunskapskanalen {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -524px -97px;
  width: 26px;
  height: 26px;
}

.largelogo-A_214 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -768px -97px;
  width: 47px;
  height: 17px;
}

.largelogo-A_215 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -825px -97px;
  width: 19px;
  height: 26px;
}

.largelogo-A_217, 
.largelogo-eurosport-2-hd,
.largelogo-eurosport-2 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -560px -98px;
  width: 49px;
  height: 26px;
}

.largelogo-A_219 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -948px -100px;
  width: 47px;
  height: 12px;
}

.largelogo-A_22 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -51px -102px;
  width: 31px;
  height: 26px;
}

.largelogo-A_221 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -317px -106px;
  width: 47px;
  height: 14px;
}

.largelogo-A_222 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -209px -108px;
  width: 47px;
  height: 14px;
}

.largelogo-A_223 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -374px -108px;
  width: 21px;
  height: 26px;
}

.largelogo-A_224 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -671px -108px;
  width: 36px;
  height: 26px;
}

.largelogo-A_225 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -1005px -100px;
  width: 8px;
  height: 26px;
}

.largelogo-A_227 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -92px -117px;
  width: 47px;
  height: 20px;
}

.largelogo-A_229 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -405px -118px;
  width: 52px;
  height: 26px;
}

.largelogo-A_230,
.largelogo-tv4-fakta {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -484px -108px;
  width: 20px;
  height: 26px;
}

.largelogo-A_231 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -717px -124px;
  width: 49px;
  height: 26px;
}

.largelogo-A_24 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -898px -126px;
  width: 47px;
  height: 15px;
}

.largelogo-A_240 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -619px -117px;
  width: 26px;
  height: 26px;
}

.largelogo-A_241 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -955px -122px;
  width: 36px;
  height: 26px;
}

.largelogo-A_246,
.largelogo-kanal-9 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -776px -124px;
  width: 21px;
  height: 26px;
}

.largelogo-A_247 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -149px -128px;
  width: 47px;
  height: 16px;
}

.largelogo-A_248 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -854px -129px;
  width: 26px;
  height: 26px;
}

.largelogo-A_25 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -206px -132px;
  width: 47px;
  height: 18px;
}

.largelogo-A_251 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -317px -130px;
  width: 31px;
  height: 26px;
}

.largelogo-A_252 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -0px -132px;
  width: 28px;
  height: 26px;
}

.largelogo-A_253 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -263px -133px;
  width: 27px;
  height: 26px;
}

.largelogo-A_255 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -514px -134px;
  width: 47px;
  height: 18px;
}

.largelogo-A_256 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -655px -144px;
  width: 47px;
  height: 19px;
}

.largelogo-A_257 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -38px -147px;
  width: 47px;
  height: 20px;
}

.largelogo-A_258 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -890px -151px;
  width: 47px;
  height: 19px;
}

.largelogo-A_259 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -807px -133px;
  width: 35px;
  height: 26px;
}

.largelogo-A_260 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -571px -134px;
  width: 32px;
  height: 26px;
}

.largelogo-A_261 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -358px -144px;
  width: 35px;
  height: 26px;
}

.largelogo-A_262 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -467px -144px;
  width: 22px;
  height: 26px;
}

.largelogo-A_263 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -95px -154px;
  width: 47px;
  height: 21px;
}

.largelogo-A_264 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -152px -154px;
  width: 33px;
  height: 26px;
}

.largelogo-A_265 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -403px -154px;
  width: 47px;
  height: 16px;
}

.largelogo-A_266 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -613px -153px;
  width: 26px;
  height: 26px;
}

.largelogo-A_267 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -947px -158px;
  width: 47px;
  height: 22px;
}

.largelogo-A_268 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -195px -160px;
  width: 47px;
  height: 15px;
}

.largelogo-A_269 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -712px -160px;
  width: 35px;
  height: 26px;
}

.largelogo-A_271 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -499px -162px;
  width: 47px;
  height: 16px;
}

.largelogo-A_272 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -757px -160px;
  width: 20px;
  height: 26px;
}

.largelogo-A_275 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -300px -166px;
  width: 34px;
  height: 26px;
}

.largelogo-A_28 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -852px -165px;
  width: 26px;
  height: 26px;
}

.largelogo-A_29 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -787px -169px;
  width: 47px;
  height: 17px;
}

.largelogo-A_292 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -252px -169px;
  width: 31px;
  height: 26px;
}

.largelogo-A_293 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -649px -173px;
  width: 47px;
  height: 23px;
}

.largelogo-A_295 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -0px -177px;
  width: 47px;
  height: 25px;
}

.largelogo-A_298 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -344px -180px;
  width: 47px;
  height: 20px;
}

.largelogo-A_299 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -401px -180px;
  width: 47px;
  height: 19px;
}

.largelogo-A_3 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -556px -170px;
  width: 16px;
  height: 26px;
}

.largelogo-A_300 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -888px -180px;
  width: 48px;
  height: 23px;
}

.largelogo-A_301 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -57px -185px;
  width: 48px;
  height: 23px;
}

.largelogo-A_302 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -195px -185px;
  width: 42px;
  height: 26px;
}

.largelogo-A_303 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -458px -180px;
  width: 28px;
  height: 26px;
}

.largelogo-A_304 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -496px -188px;
  width: 47px;
  height: 21px;
}

.largelogo-A_305 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -582px -189px;
  width: 45px;
  height: 8px;
}

.largelogo-A_32 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -115px -185px;
  width: 26px;
  height: 26px;
}

.largelogo-A_321 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -151px -190px;
  width: 27px;
  height: 26px;
}

.largelogo-A_328 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -946px -190px;
  width: 28px;
  height: 26px;
}

.largelogo-A_329 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -706px -196px;
  width: 47px;
  height: 16px;
}

.largelogo-A_330 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -763px -196px;
  width: 47px;
  height: 13px;
}

.largelogo-A_331 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -820px -201px;
  width: 47px;
  height: 16px;
}

.largelogo-A_332 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -293px -202px;
  width: 34px;
  height: 26px;
}

.largelogo-A_333 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -984px -190px;
  width: 26px;
  height: 26px;
}

.largelogo-A_334 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -247px -205px;
  width: 26px;
  height: 26px;
}

.largelogo-A_337 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -637px -206px;
  width: 20px;
  height: 26px;
}

.largelogo-A_338 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -667px -206px;
  width: 20px;
  height: 26px;
}

.largelogo-A_339 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -553px -207px;
  width: 20px;
  height: 26px;
}

.largelogo-A_34 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -337px -210px;
  width: 47px;
  height: 15px;
}

.largelogo-A_340 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -583px -207px;
  width: 20px;
  height: 26px;
}

.largelogo-A_341 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -401px -209px;
  width: 31px;
  height: 26px;
}

.largelogo-A_342 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -0px -212px;
  width: 40px;
  height: 26px;
}

.largelogo-A_346 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -877px -213px;
  width: 25px;
  height: 26px;
}

.largelogo-A_347 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -912px -213px;
  width: 22px;
  height: 26px;
}

.largelogo-A_348 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -50px -218px;
  width: 47px;
  height: 6px;
}

.largelogo-A_350 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -442px -216px;
  width: 39px;
  height: 26px;
}

.largelogo-A_352 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -491px -219px;
  width: 47px;
  height: 19px;
}

.largelogo-A_353 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -188px -221px;
  width: 47px;
  height: 17px;
}

.largelogo-A_354,
.largelogo-tv10 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -763px -219px;
  width: 28px;
  height: 26px;
}

.largelogo-A_355 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -107px -221px;
  width: 26px;
  height: 26px;
}

.largelogo-A_356 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -697px -222px;
  width: 47px;
  height: 20px;
}

.largelogo-A_357 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -944px -226px;
  width: 47px;
  height: 20px;
}

.largelogo-A_358 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -801px -227px;
  width: 47px;
  height: 20px;
}

.largelogo-A_359 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -337px -235px;
  width: 47px;
  height: 20px;
}

.largelogo-A_36 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -50px -234px;
  width: 42px;
  height: 26px;
}

.largelogo-A_360 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -245px -241px;
  width: 45px;
  height: 12px;
}

.largelogo-A_37 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -613px -242px;
  width: 47px;
  height: 11px;
}

.largelogo-A_38 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -548px -243px;
  width: 47px;
  height: 20px;
}

.largelogo-A_4,
.largelogo-tv4 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -143px -226px;
  width: 26px;
  height: 26px;
}

.largelogo-A_40 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -179px -248px;
  width: 47px;
  height: 13px;
}

.largelogo-A_41 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -300px -238px;
  width: 19px;
  height: 26px;
}

.largelogo-A_42 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -394px -245px;
  width: 34px;
  height: 26px;
}

.largelogo-A_423 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -858px -249px;
  width: 47px;
  height: 20px;
}

.largelogo-A_43 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -0px -248px;
  width: 24px;
  height: 26px;
}

.largelogo-A_44 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -491px -248px;
  width: 20px;
  height: 26px;
}

.largelogo-A_45 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -438px -252px;
  width: 34px;
  height: 26px;
}

.largelogo-A_47 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -670px -252px;
  width: 67px;
  height: 26px;
}

.largelogo-A_471 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -915px -256px;
  width: 47px;
  height: 20px;
}

.largelogo-A_489 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -747px -257px;
  width: 47px;
  height: 20px;
}

.largelogo-A_49 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -972px -256px;
  width: 35px;
  height: 26px;
}

.largelogo-A_5,
.largelogo-kanal-5 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -102px -257px;
  width: 26px;
  height: 26px;
}

.largelogo-A_520 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -236px -263px;
  width: 48px;
  height: 16px;
}

.largelogo-A_533 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -804px -257px;
  width: 29px;
  height: 26px;
}

.largelogo-A_58 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -605px -263px;
  width: 42px;
  height: 26px;
}

.largelogo-A_59 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -329px -265px;
  width: 47px;
  height: 15px;
}

.largelogo-A_6 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -34px -270px;
  width: 40px;
  height: 26px;
}

.largelogo-A_61,
.largelogo-tv8 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -138px -262px;
  width: 26px;
  height: 26px;
}

.largelogo-A_67 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -174px -271px;
  width: 44px;
  height: 26px;
}

.largelogo-A_6_viasat_crime {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -521px -273px;
  width: 38px;
  height: 26px;
}

.largelogo-A_7 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -843px -279px;
  width: 35px;
  height: 26px;
}

.largelogo-A_70 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -386px -281px;
  width: 34px;
  height: 26px;
}

.largelogo-A_71 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -888px -286px;
  width: 47px;
  height: 7px;
}

.largelogo-A_721,
.largelogo-tv12 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -482px -284px;
  width: 26px;
  height: 26px;
}

.largelogo-A_740 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -747px -287px;
  width: 45px;
  height: 19px;
}

.largelogo-A_76 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -657px -288px;
  width: 44px;
  height: 26px;
}

.largelogo-A_8 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -228px -289px;
  width: 45px;
  height: 26px;
}

.largelogo-A_82, 
.largelogo-svt24-hd,
.largelogo-svt24  {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -283px -290px;
  width: 45px;
  height: 14px;
}

.largelogo-A_83 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -430px -288px;
  width: 26px;
  height: 26px;
}

.largelogo-A_85 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -945px -292px;
  width: 40px;
  height: 26px;
}

.largelogo-A_88 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -84px -298px;
  width: 47px;
  height: 11px;
}

.largelogo-A_89 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -569px -299px;
  width: 47px;
  height: 12px;
}

.largelogo-A_9 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -888px -303px;
  width: 45px;
  height: 17px;
}

.largelogo-A_90 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -0px -306px;
  width: 47px;
  height: 19px;
}

.largelogo-A_91 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -141px -307px;
  width: 47px;
  height: 19px;
}

.largelogo-A_94 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -283px -314px;
  width: 47px;
  height: 23px;
}

.largelogo-A_95 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -340px -290px;
  width: 26px;
  height: 26px;
}

.largelogo-A_96 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -802px -293px;
  width: 26px;
  height: 26px;
}

.largelogo-A_98.gif {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -518px -309px;
  width: 27px;
  height: 26px;
}

.largelogo-A_98 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -838px -315px;
  width: 26px;
  height: 26px;
}

.largelogo-boomerang_eur {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -711px -316px;
  width: 72px;
  height: 26px;
}

.largelogo-canal + sportextra {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -376px -324px;
  width: 128px;
  height: 16px;
}

.largelogo-cnbc {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -57px -319px;
  width: 34px;
  height: 26px;
}

.largelogo-crime_logo {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -555px -321px;
  width: 39px;
  height: 26px;
}

.largelogo-fashion_men {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -604px -321px;
  width: 35px;
  height: 26px;
}

.largelogo-history_channel {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -101px -319px;
  width: 26px;
  height: 26px;
}

.largelogo-hrt1 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -874px -330px;
  width: 82px;
  height: 26px;
}

.largelogo-kabel_eins {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -649px -324px;
  width: 38px;
  height: 26px;
}

.largelogo-kanal4_dk {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -198px -325px;
  width: 28px;
  height: 26px;
}

.largelogo-kanal5_dk {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -236px -325px;
  width: 20px;
  height: 26px;
}

.largelogo-mtvn_hd {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -266px -347px;
  width: 70px;
  height: 26px;
}

.largelogo-nasn {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -346px -350px;
  width: 104px;
  height: 26px;
}

.largelogo-pink_plus {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -460px -350px;
  width: 60px;
  height: 26px;
}

.largelogo-pro7 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -966px -328px;
  width: 26px;
  height: 26px;
}

.largelogo-rtl2 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -793px -329px;
  width: 27px;
  height: 26px;
}

.largelogo-russia_today_tv {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -137px -336px;
  width: 48px;
  height: 26px;
}

.largelogo-sailing_channel {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -697px -352px;
  width: 69px;
  height: 26px;
}

.largelogo-the_voice_tv {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -0px -355px;
  width: 66px;
  height: 26px;
}

.largelogo-TV1000_HD {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -530px -357px;
  width: 100px;
  height: 19px;
}

.largelogo-tv2_charlie {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -776px -366px;
  width: 156px;
  height: 26px;
}

.largelogo-TV2_dk {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -942px -366px;
  width: 62px;
  height: 26px;
}

.largelogo-tv2_nyhetskanalen {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -76px -383px;
  width: 234px;
  height: 26px;
}

.largelogo-tv2_zulu_denmark {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -320px -386px;
  width: 157px;
  height: 26px;
}

.largelogo-tv3 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -640px -360px;
  width: 26px;
  height: 26px;
}

.largelogo-TVE {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -487px -386px;
  width: 42px;
  height: 26px;
}

.largelogo-vh1_classic {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -539px -386px;
  width: 40px;
  height: 26px;
}

.largelogo-Viasat-Nature {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -589px -386px;
  width: 39px;
  height: 26px;
}

.largelogo-viasat_4 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -676px -388px;
  width: 26px;
  height: 26px;
}

.largelogo-viasat_crime {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -712px -388px;
  width: 38px;
  height: 26px;
}

.largelogo-Viasat_hockey {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -760px -402px;
  width: 78px;
  height: 26px;
}

.largelogo-viasat_nature {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -0px -391px;
  width: 38px;
  height: 26px;
}

.largelogo-viasat_tv3_plus_dk {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -638px -396px;
  width: 26px;
  height: 26px;
}

.largelogo-vox {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -848px -402px;
  width: 81px;
  height: 26px;
}

.largelogo-V_130 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -939px -402px;
  width: 46px;
  height: 13px;
}

.largelogo-V_135 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -48px -419px;
  width: 45px;
  height: 15px;
}

.largelogo-V_137 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -103px -419px;
  width: 47px;
  height: 16px;
}

.largelogo-V_138 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -160px -419px;
  width: 47px;
  height: 16px;
}

.largelogo-V_139 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -217px -419px;
  width: 47px;
  height: 16px;
}

.largelogo-V_140 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -274px -422px;
  width: 47px;
  height: 16px;
}

.largelogo-V_141 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -331px -422px;
  width: 47px;
  height: 16px;
}

.largelogo-V_142 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -388px -422px;
  width: 47px;
  height: 16px;
}

.largelogo-V_143 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -445px -422px;
  width: 47px;
  height: 16px;
}

.largelogo-V_2,
.largelogo-tv6 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -502px -422px;
  width: 26px;
  height: 26px;
}

.largelogo-V_4 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -538px -422px;
  width: 28px;
  height: 26px;
}

.largelogo-yle_fst5 {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -576px -432px;
  width: 129px;
  height: 26px;
}

.largelogo-yle_teema {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -715px -438px;
  width: 100px;
  height: 13px;
}

.largelogo-zone_reality {
  display: inline-block;
  background-image: url(../images/largelogo-.webp);
  background-position: -939px -425px;
  width: 38px;
  height: 26px;
}