@import "../style/constant.scss";
@import "../style/mixins.scss";

.my-list {
  margin: 0 0 2rem 0;
  background: rgb(0, 116, 170); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(0, 116, 170, 1) 7%,
    rgba(0, 116, 170, 1) 7%,
    rgba(0, 116, 170, 1) 32%,
    rgba(97, 92, 130, 1) 70%,
    rgba(97, 92, 130, 1) 88%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 116, 170, 1) 7%,
    rgba(0, 116, 170, 1) 7%,
    rgba(0, 116, 170, 1) 32%,
    rgba(97, 92, 130, 1) 70%,
    rgba(97, 92, 130, 1) 88%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 116, 170, 1) 7%,
    rgba(0, 116, 170, 1) 7%,
    rgba(0, 116, 170, 1) 32%,
    rgba(97, 92, 130, 1) 70%,
    rgba(97, 92, 130, 1) 88%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0074aa', endColorstr='#615c82',GradientType=0 ); /* IE6-9 */
  border-radius: 10px;
  min-height: 300px;
  h2 {
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
    margin-bottom: 1rem;
    padding: 0.5rem;
    .print-icon {
      a {
        color: white;
      }
    }
  }
  .text {
    color: #fff;
    padding: 0.5rem;
    display: block;
    font-size: 11px;
  }
  ul {
    padding: 0 5px 15px 5px;
    margin: 0;
    li {
      list-style: none;
      color: #fff;
      font-size: 11px;
      margin-bottom: 10px;
      cursor: pointer;
      .smalllogo {
        float: left;
        margin-right: 5px;
        width: 30px;
        img {
          max-width: 100%;
        }
      }
      .remove-icon {
        float: right;
        display: inline-block;
        background: #fff;
        height: 16px;
        width: 16px;
        border-radius: 10px;
        cursor: pointer;
        svg {
          float: right;
          color: #c32626;
          font-size: 14px;
          margin-top: 1px;
          margin-right: 1px;
        }
      }
      .name {
        float: left;
        width: calc(100% - 55px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

.forgot-password {
  .footer {
    margin-top: 25%;
  }
}

.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1021;
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  .modal-inner-container {
    background: rgba(256, 256, 256, 1);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    position: relative;
    height: 100%;
    overflow-y: auto;
    max-height: 80%;
    width: 500px;
    margin: 0 auto;
    margin-top: 8vh;
    overflow-x: hidden;
  }
  .modal-footer.modal-footer {
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.5);
    padding: 1rem 20px;
    margin-top: 10px;
    border-radius: 0;
    position: absolute;
    bottom: 0;
    border-radius: 0 0 6px 6px;
    width: 100%;
    background: #fff;
  }
  .close-modal {
    position: absolute;
  }
}
.switch-layout {
  font-size: 0.8rem;
  float: right;
  display: flex;
  align-items: center;
  margin: 5px 0;
  span {
    cursor: pointer;
  }
  .toggle-btn {
    min-width: 30px;
    min-height: 12px;
  }
}

.add-container {
  #cncpt-lb1 {
    max-width: 100%;
  }
  iframe {
    max-width: 100%;
  }
}
.filter {
  label {
    margin-right: 10px;
  }
  select {
    border: 1px #dcdfe6 solid;
    background-color: #4c9fc1;
    margin: 2px 0 0 0;
    width: 150px;
  }
}

.search-result {
  margin-top: -40px;
  .action-bar {
    margin-bottom: 20px;
    .heading {
      line-height: 23px;
      background: url("../images/new/menu_bg_unselected.gif");
      font-weight: bold;
      font-size: 11px;
      border-radius: 4px 4px 0 0;
      display: block;
      color: #fff;
      padding: 0 20px;
    }

    .filters {
      border: 1px solid #c6c6c6;
      border-radius: 0 0 4px 4px;
      padding: 5px 20px;
      .label {
        display: inline-block;
        margin-right: 20px;
      }
      select {
        height: 25px;
        margin-right: 20px;
      }
    }
  }
  .search-list {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      border-top: solid 1px #474747;
      height: 40px;
      line-height: 40px;

      > span {
        display: block;
        width: 25%;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .anchor-link {
        width: calc(50% - 130px);
      }
      .logo {
        text-align: right;
        width: 130px;
        background: none;
        height: 40px;
        // padding-top: 3px;
        margin: 0;
        img {
          max-width: 60px;
          max-height: 35px;
          vertical-align: middle;
        }
        span {
          float: right;
        }
      }
    }
  }
}

.anchor-link {
  color: #00a0dc;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
.myProgramList-modal.myProgramList-modal.myProgramList-modal {
  min-height: 300px;
  height: 400px;
  overflow: hidden;
  .close {
    background: transparent;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    position: absolute;
    right: 10px;
    top: 5px;
    line-height: 24px;
    color: #fff;
    margin: 0;
    padding: 0;
    opacity: 1;
    font-size: 15px;
  }
  .my-list {
    height: 100%;
    overflow-y: auto;
    padding: 10px 20px;
    .print-icon {
      margin-right: 20px;
    }
  }
}

.sponsorBanner {
  position: relative;
  background-color: #e8ecf1;
  padding: 20px;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 40px;
  max-width: 1024px;

  .title {
    color: #5d6164;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #d1d3d6;
    padding: 4px;
    position: absolute;
    left: 0;
    top: -10px;
    font-weight: bold;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    li {
      display: inline-block;
      padding: 0 5px;
      margin: 0 5px;
      flex: 1 1 28%;
      max-width: 28%;
      // &:last-child {
      //   flex: 1 1 16%;
      //   max-width: 16%;
      // }
      img {
        max-width: 100%;
      }
    }
  }
}
@include allMobiles {
  .sponsorBanner {
    padding: 20px 10px;
    ul {
      display: block;
      li {
        max-width: calc(100% - 20px);
        margin: 0;
        img {
          max-width: 100%;
        }
      }
    }
  }
}
.my-list.my-list.my-list {
  .stream-list.stream-list.stream-list {
    li {
      display: flex;
      justify-content: flex-start;
      padding: 10px;
      align-items: center;
      position: relative;
      &:nth-child(even) {
        background-color: #e4e4e4;
      }
      .image {
        max-width: 120px;
        margin-right: 15px;
        img {
          max-width: 100%;
        }
      }
      h3 {
        font-size: 16px;
        color: #222;
      }
      .close-btn {
        position: absolute;
        right: 13px;
        color: #222;
        font-weight: 500;
        font-size: 20px;
        padding: 3px;
        cursor: pointer;
        top: 3px;
      }
    }
  }
}
.show-mylist {
  display: none;
  .showlist {
    border: 0;
    background: #f5a600;
    margin: 5px 0;
    border-radius: 4px;
    padding: 3px 5px;
  }
}
.channel-list-info {
  margin: 0 auto;
  margin-top: 40px;
  background: #2e2e2e;
  color: #fff;
  max-width: 780px;
  padding: 10px;
  border-radius: 10px;
  p {
    color: #fff;
    float: left;
    width: calc(100% - 60px);
    margin-bottom: 0;
  }
  .icon {
    background-image: url("../images/new/info_bg.gif");
    height: 40px;
    width: 40px;
    float: left;
    background-position: -13px -8px;
    border-radius: 50%;
    margin-right: 20px;
  }
}

.welcome-message {
  background-color: #fff;
  box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.2);
  border-radius: 8px;
  padding: 20px 20px 5px 40px;
  margin-bottom: 20px;
  position: relative;
  .text {
    float: left;
    width: calc(100% - 120px);
    .close-btn {
      position: absolute;
      right: 13px;
      color: #222;
      font-weight: 500;
      font-size: 20px;
      padding: 3px;
      cursor: pointer;
      top: 3px;
    }
  }
  h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  i {
    display: block;
    float: left;
    margin-right: 40px;
    margin-top: 20px;
  }
}

.sidebar {
  margin-top: 45px;
}

.sidebar-nav {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      line-height: 55px;
      height: 55px;
      width: 110px;
      border-bottom: solid 1px #bfbfbf;
      a {
        padding: 0;
        color: #575757;
        &:hover {
          color: #1e9cbc;
        }
        &.active {
          font-weight: bold;
          color: #1e9cbc;
        }
      }
    }
  }
}

.static-content {
  h1 {
    color: #f90;
    font-size: 16px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
    }
  }
}

.rerun-modal {
  h5 {
    font-size: 18px;
  }
  .text {
    font-size: 15px;
    margin-left: 20px;
  }
}

.top-add-container.sticky {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

a.admin-link {
  color: #98ad43;
}

@include respond-below(xs) {
  .modal-overlay {
    .modal-inner-container {
      max-width: 100%;
    }
  }
  .show-mylist {
    display: block;
  }
  .switch-layout {
    display: none;
  }
  .sidebar {
    display: none;
  }
  .info-bar {
    .col-xs-6 {
      width: 50%;
    }
    .filter {
      label {
        margin: 0;
      }
    }
    .category-drop-down {
      margin: 0 0 5px 0;
      width: 105px;
    }
  }
  .main-content {
    .container {
      padding-top: 4rem;
    }
  }
}

@include smallDesktop {
  .timeline-layout {
    .padding0 {
      max-width: 191px;
    }
    .paddingLeft0 {
      width: calc(100% - 191px);
      max-width: calc(100% - 191px);
      padding-right: 10px;
      flex: 0 0 85%;
    }
  }
}

@include smallDesktopAndMobile {
  .user-container {
    padding: 0 30px;
  }
  .user-container .my-list {
    max-width: 80%;
    // margin: 0 auto;
  }
  .col-md-10.noPadding.noPadding.noPadding {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px;
  }
}

@include tablet {
  .timeline-layout {
    .padding0 {
      max-width: 191px;
    }
    .paddingLeft0 {
      width: calc(100% - 191px);
      max-width: calc(100% - 191px);
      padding-right: 10px;
      flex: 0 0 85%;
    }
  }
}
.sticky-top {
  z-index: 998 !important;
}

.no-height.no-height.no-height {
  height: 0;
}

@include allMobiles {
  .myProgramList-modal.myProgramList-modal.myProgramList-modal {
    width: 90%;
    .my-list {
      border-radius: 0;
    }
  }
  .info-bar {
    height: 45px;
  }
  .show-mylist {
    display: block;
    text-align: right;
    position: absolute;
    width: 195px;
    right: 0;
    top: 5px;
    .showlist {
      margin: 5px;
    }
  }
  .switch-layout {
    display: none;
  }
  .sidebar {
    display: none;
  }
  .static-page {
    h3 {
      font-size: 1.2rem;
    }
  }
  .user-container .my-list {
    max-width: 80%;
  }
  .timeline-layout {
    padding: 0 20px;
    .paddingLeft0 {
      padding: 0;
    }
  }
  .program-list {
    margin: 0;
    justify-content: center;
    .item {
      width: calc(50% - 6px);
      max-width: calc(50% - 6px);
    }
  }
  h2 {
    font-size: 22px;
  }
  h4 {
    font-size: 18px;
  }
  .setting-page {
    .col-6 {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .col-md-10.noPadding.noPadding.noPadding {
    padding: 0;
  }
  .search-result {
    .action-bar {
      .filters {
        padding: 5px 10px;
        .label,
        select {
          margin-right: 10px;
        }
        select {
          width: 125px;
        }
      }
      .heading {
        padding: 0 10px;
      }
    }
    .search-list {
      li {
        height: auto;
        width: 100%;
        position: relative;
        line-height: 40px;
        padding-bottom: 5px;
        .anchor-link {
          clear: both;
          float: none;
          width: 100%;
          line-height: 20px;
        }
        .logo {
          position: absolute;
          right: 0;
          top: 5px;
        }
      }
    }
  }
  .search-active {
    padding: 0 15px;
  }
  .welcome-message {
    padding: 20px 20px 5px 10px;
    i {
      margin-right: 20px;
    }
    .text {
      width: calc(100% - 90px);
      p {
        margin-bottom: 10px;
      }
    }
  }
  .sidebar-nav {
    display: none;
  }
  .main-content {
    &.container {
      padding-bottom: 20px;
    }
  }
  // .top-add-container {
  //   height: 480px;
  //   #cncpt-mob1 {
  //     position: sticky;
  //     top: 0;
  //   }
  // }
  .top-add-container {
    height: 480px;
    min-height: 570px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    .ad-placeholder {
      min-height: 570px;
      // background: #e8ecf1;
      width: 320px;
      min-width: 320px;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      > div,
      #cncpt-mob1 {
        position: sticky;
        top: 66px !important;
        margin: 0 !important;
        width: 100%;
      }
    }
  }

  // .top-add-container{

  //     transition: all 0.35s; display: block; position: fixed; top:0; display: flex; justify-content: center; align-items: center; height: 480px;  top: 0;
  //     left: 0;
  //     background: #fff;
  //     width: 100%;
  //     z-index: 999;    }

  //   `
  .grid-add-container-outer {
    // height: 480px;
    background: rgb(190, 189, 189);
    width: 100%;
    position: relative;
  }
  .grid-add-container {
    //   height: 480px;
    //   transition: all .5s ease-in;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #fff;
    width: 100%;
    position: relative;
    //   border:1px solid red;
  }
  .grid-add-container-height {
    height: 320px;
  }
  .mob-1st-ads {
    margin: 0 0 20px 0;
  }
}
#adscontainer {
  width: 100%;
}
.ad-label {
  color: #666;
  font-size: 14px;
  position: relative;
  padding: 0 0px;
  width: 100%;
  display: block;
  text-align: center;
  align-items: center;
  margin-bottom: 5px;
  min-width: 120px;
  &:before {
    content: "";
    height: 1px;
    width: 100%;
    display: block;
    position: absolute;
    background: #ccc;
    top: 10px;
  }
  span {
    padding: 0 10px;
    background: #fff;
    position: relative;
    z-index: 1;
  }
}
.pagination {
  justify-content: center;
  margin-top: 20px;
  li {
    a {
      color: #fff;
      text-align: center;
      margin: 0 3px;
      height: 24px;
      min-width: 24px;
      line-height: 24px;
      padding: 0 2px;
      display: inline-block;
      background-color: #747474;
    }
    &.active {
      a {
        background: rgb(32, 124, 202); /* Old browsers */
        background: -moz-linear-gradient(
          top,
          rgba(32, 124, 202, 1) 0%,
          rgba(32, 124, 202, 1) 12%,
          rgba(32, 124, 202, 1) 12%,
          rgba(32, 124, 202, 1) 20%,
          rgba(41, 137, 216, 1) 44%,
          rgba(30, 87, 153, 1) 91%,
          rgba(30, 87, 153, 1) 91%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          top,
          rgba(32, 124, 202, 1) 0%,
          rgba(32, 124, 202, 1) 12%,
          rgba(32, 124, 202, 1) 12%,
          rgba(32, 124, 202, 1) 20%,
          rgba(41, 137, 216, 1) 44%,
          rgba(30, 87, 153, 1) 91%,
          rgba(30, 87, 153, 1) 91%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to bottom,
          rgba(32, 124, 202, 1) 0%,
          rgba(32, 124, 202, 1) 12%,
          rgba(32, 124, 202, 1) 12%,
          rgba(32, 124, 202, 1) 20%,
          rgba(41, 137, 216, 1) 44%,
          rgba(30, 87, 153, 1) 91%,
          rgba(30, 87, 153, 1) 91%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#207cca', endColorstr='#1e5799',GradientType=0 );
      }
    }
    &.previous,
    &.next {
      a {
        width: 70px;
        padding: 0 5px;
      }
    }
  }
}
.sliderSection {
  margin: 0 -15px;
  h2 {
    font-size: 20px;
    color: #575757;
    margin-bottom: 10px;
  }
}

.homeSlider {
  position: relative;
  margin: 0 -5px 60px -5px;
  // margin-bottom: 20px;
  .item {
    height: 165px;
    width: 240px;
    cursor: pointer;
  }
}

.play-icon {
  background: url("../images/ic_play.svg") 0 0 no-repeat;
  height: 28px;
  width: 28px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
}
.addTOlist-icon {
  background: url("../images/ic_addToList.svg") 0 0 no-repeat;
  height: 28px;
  width: 28px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
}

.addTOlist-icon2 {
  background: url("../images/ic_add-to-list-icon2.svg") 0 0 no-repeat;
  height: 35px;
  width: 35px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
}

.like-icon {
  background: url("../images/ic_thumb-up-unchecked.svg") 0 0 no-repeat;
  height: 35px;
  width: 35px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
}
.dislike-icon {
  background: url("../images/ic_thumb-down-unchecked.svg") 0 0 no-repeat;
  height: 35px;
  width: 35px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
}

.heart-icon {
  background: url("../images/ic_heart.svg") 0 0 no-repeat;
  height: 35px;
  width: 35px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  fill: #fff;
}

.like-icon,
.dislike-icon,
.addTOlist-icon2 {
  opacity: 0.5;
}

.like-icon.liked,
.dislike-icon.disliked,
.addTOlist-icon2.watched {
  opacity: 1;
}

.disneyplus_icon {
  background: url("../images/provider/disneyplus.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
}

.NETFLIX_icon,
.netflix_icon {
  background: url("../images/provider/netflix.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.PRIME_VIDEO_icon,
.primevideo_icon,
.amazonprimevideo_icon {
  background: url("../images/provider/AmazonPrime.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.VIA_PLAY_icon,
.viaplay_icon {
  background: url("../images/provider/vaiplay.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.C_MORE_icon,
.cmore_icon {
  background: url("../images/provider/cMore.svg") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.HBO_NORDIC_icon,
.hbo_nordic,
.hbo_icon {
  background: url("../images/provider/hbomax.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.DISCOVERY_PLUS_icon,
.dplay_icon {
  background: url("../images/provider/discoveryplus.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.HBO_MAX_icon,
.hbomax_icon {
  background: url("../images/provider/hbomax.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.APPLE_TV_icon,
.appletv_icon {
  background: url("../images/provider/appletv.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.appletvplus_icon {
  background: url("../images/provider/appletv_plus.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.viafree_icon {
  background: url("../images/provider/viafree.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.plejmo_icon {
  background: url("../images/plejmo-icon.svg") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.tv4play_icon {
  background: url("../images/fyran-logo.svg") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.svtplay_icon {
  background: url("../images/provider/svt.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.sfanytime_icon {
  background: url("../images/provider/sftime.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.streamly_icon {
  background: url("../images/streamly-text-logo.svg") 0 0 no-repeat;
  height: 70px;
  width: 70px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.rakutentv_icon {
  background: url("../images/provider/rakutenTV.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.skyshowtime_icon {
  background: url("../images/provider/SkyShowtime.webp") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.britbox_icon {
  background: url("../images/provider/britBox.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.blockbuster_icon {
  background: url("../images/provider/blockbuster.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.blockbuster_icon {
  background: url("../images/provider/blockbuster.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}

.plutotv_icon {
  background: url("../images/provider/plutoTV.png") 0 0 no-repeat;
  height: 40px;
  width: 40px;
  background-size: 100% 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  vertical-align: middle;
}
.player-container {
  position: relative;
  padding-top: 56.25%;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

@include smallDesktopAndMobile {
  .sliderSection {
    margin: 0;
  }
  .main-content {
    .container {
      max-width: 100%;
    }
  }
  .homeSlider {
    margin: 0 0 50px 0;
  }
}
@include respond-below(xs) {
  .stream-play {
    .modal-header {
      padding: 0;
    }
    .modal-body {
      padding: 0;
    }
  }
  .close {
    z-index: 9;
  }
}
