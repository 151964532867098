@import '../../style/constant';
@import '../../style/mixins';
.footer{
    color: #fff;
    margin-top: 1rem;
    border-top: 2px solid #575757;
    padding: 15px;
    font-size: 12px;
    background-color: #1c1d1d;
    h2{
        font-size: 18px;
        line-height: 1.2;
        font-weight: 700;
        margin-bottom: 10px;
        color: #fff;
    }
    p{
        line-height: 1.4;
        letter-spacing: -.1px;
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        color:#c4d0e6;
    }
    .col-sm-4{
        padding: 0 0;
        text-align: left;
    }
    nav{
        ul{
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                margin: 0;
                padding: 0;
                display: inline-block;
                border-left: 1px solid #645959;
                &:first-child{
                    border: 0 ;
                }
            }
        }
        a{
            display: inline-block;
            margin: 0 0.5rem;
            color: #c4d0e6;
            padding: 0.05rem 1rem;
            position: relative;
            line-height: 14px;
            border-left: 1px solid #c4d0e6;
            // &:hover, .active{
            //     @include hoverAnimation
            // }
            &:first-child{
                border: 0;
                margin-left:0;
            }
        }
    }
    p{
        margin: 0 0 15px 0;
    }
    .col-sm-4{
        padding-right: 30px;
    }
    h3{
        color:#f2f2f2;
        font-size: 16px;
        text-transform: uppercase;
        border-bottom: 1px solid #fff;
        a{
            color:#f2f2f2;
            display:inline-block;
            line-height: 20px;
        }
    }
    .row{
        margin: 0;
    }
    .copyright{
        padding-top: 20px;
        border-top: 1px solid #fff;
        text-align: center;
    }
}

@include allMobiles{
    .footer{
        nav{
            a{
                margin: 0;
                padding: 0 8px;
            }
        }
        p{
            padding-top: 10px;
            text-align: center;
        }
    }

}
