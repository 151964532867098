@import "../../style/mixins.scss";
@import "../../style/constant.scss";

.channel-detail-page{
    .top-logo{
        padding-top:20px;
        display: flex;
        position: relative;
        background: none;
        top:0;

        .channel-logo{
            margin-right: 20px;
        }
        .name{
            margin-bottom: 0;
            display: inline-block;
            a{
                color: #000; 
                font-size: 24px;
            }
           
        }
    }
    
    .grid-add-container{
        margin: 0;
    }
    .channel-detail-list{
        padding: 0;
        margin: 0 0 20px 0;
        width: 100%;
        display: block;
        .channel{
            width: 100%;
            max-width: 100%; 
            margin: 0;
            background: none;
        }
        ul{
            padding: 0;
            margin: 0;
            li{
                margin: 0 0 2px 0;
                padding: 20px 20px 20px 100px;
                list-style: none;
                background: #f7f7f7;
                // border-top:1px solid #ccc;
                font-size: 14px;
                position: relative;
                width: 100%;
                max-width: 100%;
               
                &::before{
                    content: "";
                    width: 2px;
                    height: calc(100% - 40px);
                    left:80px;
                    background: #ccc;
                    position: absolute;
                }
                h3{
                    font-size: 20px;
                    font-weight: bold;
                    .time{
                        position: absolute;
                        left:10px;
                        color: #575757;
                    }
                    .program.program.program.program{
                        color: #575757;
                    }
                }
                p {
                    span{
                        color: #5a5a5a;
                        font-weight: bold;
                    }
                }
            }
        }
        
    }
}


@include allMobiles {
    .channel-detail-page .item .top-logo{
        top:0;
    }
    .channel-detail-page .channel-detail-list ul li h3{
        font-size: 18px;
    }
    .channel-detail-page .channel-detail-list ul li p{
        font-size: 12px;
        line-height: 14px;
        display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 10px;
    }
    .channel-detail-page .channel-detail-list ul li p span{
        font-size: 12px;
        line-height: 14px;
    }

}