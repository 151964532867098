@import "../../style/constant";
@import "../../style/mixins";

.detail {
  height: 100%;
  position: relative;
  max-width: 1600px;
  .heroImage {
    position: absolute;
    height: 400px;
    width: 100%;
    overflow: hidden;
    top: 0;
    text-align: center;
    img {
      min-width: 70%;
    }
    .overlay {
      position: absolute;
      z-index: 1;
    //   background-image: linear-gradient(
    //     180deg,
    //     rgba(0, 0, 0, 0.2) 0%,
    //     rgba(0, 0, 0, 0.35) 55%,
    //     rgba(0, 0, 0, 0.55) 100%
    //   );
      height: 100%;
      width: 100%;
      left: 0;
      bottom: 0;
      &::before {
        content: "";
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+46,000000+88&0.65+0,1+0,1+61,0+100 */
background: -moz-linear-gradient(left,  rgba(0,0,0,1) 0%, rgba(0,0,0,1) 46%, rgba(0,0,0,1) 61%, rgba(0,0,0,0.31) 88%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(0,0,0,1) 0%,rgba(0,0,0,1) 46%,rgba(0,0,0,1) 61%,rgba(0,0,0,0.31) 88%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(0,0,0,1) 0%,rgba(0,0,0,1) 46%,rgba(0,0,0,1) 61%,rgba(0,0,0,0.31) 88%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */

        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 25%;
      }
      &::after {
        content: "";
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+6,000000+46&0+0,1+33,1+47 */
background: -moz-linear-gradient(left,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.18) 6%, rgba(0,0,0,1) 33%, rgba(0,0,0,1) 46%, rgba(0,0,0,1) 47%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.18) 6%,rgba(0,0,0,1) 33%,rgba(0,0,0,1) 46%,rgba(0,0,0,1) 47%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.18) 6%,rgba(0,0,0,1) 33%,rgba(0,0,0,1) 46%,rgba(0,0,0,1) 47%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=1 ); /* IE6-9 */

        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 22%;
      }
    }
  }
  .justifySpaceBetween{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
          border-bottom: 2px solid #616161;
          margin-bottom: 20px;
          .custom-selectBox{
            max-width: calc(100% - 200px);
          }
  }





  .related-videos{
    padding-top: 20px;
    ul{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
      margin: 0;
      padding: 0;
      list-style: none;
      li{
        margin: 0;
        // padding: 5px;
        list-style: none;
        border-radius: 0;
        border:0;
      }
    }
  } 


  .season-episode{
    display: grid;
    // grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin: 0;
    padding: 0;
    list-style: none;
    li{
      margin: 0;
      // padding: 5px;
      list-style: none;
     
      border-radius: 0;
      border:0;
      
      .inner-content{
        display: flex;
        justify-content: space-between;
      }
      .image{
        // float: left;
        height: 80px;
        max-width: 100px;
        margin: 0 0 0 0;
        border-radius: 0;
        flex: 0 0 100px;
        img{
          max-height: 100%;
          transform: translateX(0);
        }
      }
      .episode-info{
        // float: left;
        width:calc(100% - 100px);
        color: #222;
        position: relative;
        background: #eeeeee;
        padding: 5px;
        .cardList-title{
          padding: 0;
          
          h3{
            color: #222;
            font-size: 13px;
            font-weight: 700;
            margin-top: 18px;
            display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
          }
         span{
          position: absolute;
          right: 0;
          top:0;
          background: #a0295b;
          padding: 2px 4px;
          color: #fff;
          font-size: 10px;
         }
        }
        p{
          color: #222;
          font-size: 12px;
          
        }
      }
    }
  }
  .detail-section {
    max-width: 100%;
    margin: 0 auto;
    // background: #F1ECEC;
    position: relative;
    z-index: 9;
   
    .top-section{
      display: flex;
    }
    .leftCol{
      width: 300px;
      flex: 0 0 300px;
      // float: left;
      position: relative;
      height: 335px;
      .user-action{
        position: absolute;
        background: #1c252f;
        padding: 10px 10px 10px 10px;
        border-radius: 10px;
        width: 100%;
        height: auto;
        bottom: 0;
        // display: flex;
        // justify-content: space-between;
        a{
          height: 30px;
          width: 30px;
          margin-right: 10px;
        }
        .title{
          // position: absolute;
          color: #fff;
          top: 5px;
          font-size: 14px;
          margin-bottom: 10px;
          display: block;

        }
        .addTOlist-icon2{
          float: right;
        }
      }
    }
    .image {
      width: 300px;
      overflow: hidden;
      border-radius: 10px;
      flex: 0 0 300px;
      margin-bottom: 10px;
      img {
        height: 295px;
        transform: translateX(-30%);
      }
    }
    .info {
      flex: 0 0 calc(100% - 300px);
      padding-left: 20px;
      // float: left;
      // width:  calc(100% - 300px);
      .top {
        display: flex;
        flex: 0 0 100%;
      }
      h1 {
        color: #222;
        font-size: 30px;
        span {
          color: #616161;
          font-size: 16px;
          display: inline-block;
        }
      }
      p {
        color: #616161;
      }
      .tag{
        background: #a0295b;
        display: inline-block;
        font-size: 10px;
        padding: 2px 10px;
        color: #fff;
        font-weight: bold;
        margin-bottom: 15px;
        a{
          color: #fff;
        }
      }
      h2 {
        color: #616161;
        font-size: 24px;
        a{
          display: inline-block;
          margin-left: 7px;
          &:first-child{
            margin-left: 0;
          }
        }
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        // max-width: 200px;
        li {
          list-style: none;
          display: flex;
          justify-content: flex-start;
          span {
            color: #616161;
          }
          .label {
            font-weight: bold;
            color: #222;
            margin-right: 20px;
            min-width: 110px;
          }
        }
      }
    }
    .program-list {
      width: 100%;
      .item{
        width: calc(33.33% - 14px);
        max-width: calc(33.33% - 14px);
        flex: 0 0 33.33%;
      }
    }
  }
}

.mail-to {
  width: 100%;
  clear: both;
}

.action-list {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 5px 10px;
    cursor: pointer;
    display: inline-block;
    span {
      margin-right: 10px;
    }
  }
}


.recomendation-list{
  padding-top: 30px;
}


.trailers-list{
  margin: 0;
  padding: 0;
  display: flex;
  li{
    flex: 0 0 calc(25% - 10px);
    max-width:calc(25% - 10px);
    position: relative;
    padding-bottom: 56%;
    height: 200px;
    iframe{
      width: 100%;
      position: absolute;
      height: 100%;
      overflow: hidden;
    }
  }
}

@include respond-above(md) {
  .detail-modal {
    .modal-dialog {
      min-width: 700px;
    }
  }
}
@include respond-below(xs){   
  .program-details{
    h3{
      font-size: 22px;
    }
  }
  .detail {
    .detail-section .top-section{
      flex-direction: column;
    }
    .detail-section{
      .leftCol, .image{
        width: 100%;
        flex: 0 0 100%;
      }
      .info {
        padding: 30px 0 0 0;
        flex-basis: 100%;
        h1{
          font-size: 22px;
        }
      }
    }
    .related-videos{
      ul{
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .season-episode{
      grid-template-columns: repeat(1, 1fr);
      li{
        .image{
          max-width: 150px;
          flex: 0 0 150px;
        }
        .episode-info{
          width: calc(100% - 150px);
        }
      }
    }
  }
    
    
  }
