@import '../../style/constant';
@import '../../style/mixins';

header{
    background:$primaryColor;
    //box-shadow: 0 1px 2px 0 rgba(0,0,0,0.14);
    padding:20px;
    
}
.logo {
    display: block;
    padding:0 10px 0 10px;
    background: #fff;
    border-radius: 10px;
    max-width: 400px;
    margin-bottom: 1rem;
    float: left;
    img{
        max-width: 400px;
        width: 100%;
        vertical-align: middle;
    }
}
.navbar{
    padding:0;
    margin-top: 15px;
}
header nav{
    border-radius: 5px;
    background: #fff;
    .film-selected{
        background: #a0295b;
        color: white;
    }

    .series-selected{
        background: #30609e;
        color: white;
    }

    .sport-selected{
        background: #3b8433;
        color: white;
    }

    a{
        color: #222;
        display: inline-block;
        padding:5px .5rem;
        border-radius: 4px;
        position: relative;
        text-transform: uppercase;
        box-shadow: 0 2px 4px 4px #ccc;
        font-size: 14px;
        margin: 0 4px;
        &:hover{
            color: #222;
            text-decoration: none;
        }
        @include hoverAnimation;
    }

    .active{
        background: #FFFFFF;
        color:#222;
        text-decoration: none;
        &:hover{
            color:#222;
        }
    }
    
    .user-link{
        position: relative;
        
        a{
            color: #fff;
            background: #222;
            box-shadow: none;
            padding:7px 0.5rem;
            svg{
                margin:-4px  10px 0 0;
            }
    
            &:hover{
                color: #fff;
                text-decoration: none;
                background: #2e2e2e;
            }
        }
       
        .dropdown{
            display: none;
            position: absolute;
            top:37px;
            z-index: 9;
            a{
                color: #222;
                display: inline-block;
                padding:5px .7rem;
                border-radius: 4px;
                position: relative;
                text-transform: uppercase;
                box-shadow: 0 2px 4px 4px #ccc;
                background: #fff;
            }
            &::before{
                content: "";
                width: 0; 
                height: 0; 
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 7px solid #fff;
                position: absolute;
                top:-7px;
                left:0;
                right: 0;
                margin: auto;
                z-index: 10;
            }
        }
        &:hover{
            .dropdown{
                display: block;
                a{
                    color: #222;
                    background: #fff;
                }
            }
            
        }
    }
}
.search{
    position: relative;
    max-width: 100%;
    margin-top: -15px;
    .icon, .btn{
        color:#000;
        font-size: 25px;
        font-weight: 900;
        width: 40px;
        height: 40px;
        display: block;
        position: absolute;
        right: 10px;
        top: -3px;
        padding: 0;
        margin: 0;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
    }

    .form-control{
        width: 0;
        margin: 0;
        padding:0;
        border:0;
        transition: 1s;
    }
}
.search-active{
    width: 100%;
    margin-bottom: 1rem;
    float: right;
   .btn{
        visibility: hidden;
        z-index: 1;
    }
    .form-control{
        padding:0 0.5rem;
        border:1px solid #ccc;
    }
    .form-control,
    .search{
        width: 100%;

    }
    .search .icon{
        color:#000
    }
}





@include respond-above(sm) {
    .search-active {
      margin-bottom: 0;
      width: calc(100% - 220px);
      float: right;
      max-width: 400px;
    }
  }
@include respond-above(md) {
    .logo {
      margin-bottom: 0;
    }
  }

@include respond-below(md){
    // .navbar{
    //     justify-content:unset;
    // }
    .container{
        max-width: 100%;
    }
    header{
        .navbar{
            a{
                padding:5px;
                margin: 0 4px;
                font-size: 12px;
            }
        }
        .dropdown.dropdown.dropdown{
           right: 0;
           top:35px;
           &::before{
           left:auto;
            right: 10px;
        }
        }
    }
}
@include respond-below(sm) {
    .logo {
      margin-top: 0;
    }
    
    .search{
        margin: 0;
    }
    .navbar{
        margin-bottom: 15px;
        a{
            min-width: 50px;
            text-align: center;
            font-size: 11px;
            &.user-link{
                min-width: 30px;
            }
        }
    }
  }

// @include respond-above(lg) {
//     header{
//         .navbar{
//             a{
//                 padding:5px 1rem;
//                 margin: 0 5px;
//             }
//             .user-link{
//                 padding:7px 0.5rem;
//             }
//         }
//     } 
// }

@include respond-below(xs){   
    header{
        .col-xs-12.col-sm-12.col-md-12.col-lg-6 {
            position:static;
        }
        .navbar{
            position: static;
            margin-bottom: 15px;
            a{
                padding: 5px 7px;
                margin: 0 2px;
                font-size: 10px;
            }
            .user-link{
                // position: absolute;
                // top: 27px;
                // right: 50px;
                svg{
                    margin-right: 0;
                }
                span{
                    display: none;
                }
            }
        }
    }
}