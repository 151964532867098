.homeSlider .item, .blog-listing .item{
    position: relative;
    overflow: hidden;
    .image{
        max-width: 100%;
        min-height: 100%;
        border-radius: 10px;
    }
    .overlay{
         position: absolute;
         top:0;
         left:0;
         height: 100%;
         width: 100%;
         background-color: rgba(0,0,0,0.4);
         z-index: 9;
         border-radius: 8px;
         display: flex;
         flex-direction: column;
         justify-content: flex-end;
         padding: 10px;
         h3{
            color:#fff;
            font-size: 14px;
            margin-bottom: 0;
            line-height: 16px;
         }
         span{
             font-size: 12px;
             color: #fff;
         }
    }
}
.homeSlider .slick-slide{
    padding: 0 5px !important;
}

.homeSlider .controls{
    z-index: 99;
    position: absolute;
}
.homeSlider .controls{
    position: absolute;
    left:0;
    right: 0;
    margin: auto;
    bottom:-40px;
    width: 120px;
    z-index: 9;
}

.homeSlider .previous-btn,
.homeSlider .next-btn{
    height: 40px;
    width: 40px;
    background-color: rgba(216,216,216,0.9);
    border: 0;
    border-radius: 50%;
    display: block;
    cursor: pointer;
}
.homeSlider .next-btn{
    float: right;
    margin-right: 5px;
    background-image: url(../images/ic_next-btn.svg);
    background-size: 10px 16px;
    background-repeat: no-repeat;
    background-position: 15px 13px;
}
.homeSlider .previous-btn{
    float: left;
    margin-left: 5px;
    background-image: url(../images/ic_prev-btn.svg);
    background-size: 10px 16px;
    background-repeat: no-repeat;
    background-position: 15px 13px;
}