.admin-root {
  .navbar {
    margin: 0 0 20px 0;
    padding: 0 20px;
    font-size: 15px;
  }

  .table-view {
    padding: 1em 0;
    border-bottom: 1px solid gray;
  }

  .margin-top-5px {
    margin-top: 5px;
  }

  .margin-bottom-5px {
    margin-bottom: 5px;
  }
  .form-control-badge {
    font-size: 12px;
    border: 1px solid lightgray;
    padding: 10px 12px;
    display: inline-block;
    border-radius: 4px;
    position: relative;
    .close-btn {
      position: absolute;
      right: -8px;
      top: -11px;
      font-size: 12px;
      cursor: pointer;
      padding: 0 6px;
      border-radius: 50%;
      background: black;
      color: white;
      z-index: 1;
    }
  }
  .admin-active {
    color: green;
  }
  .admin-inactive {
    color: red;
  }
  .error {
    color: red;
  }
}