@import '../style/constant.scss';
@import '../style/mixins.scss';
.main-content{
    overflow-x: hidden;
    .container{
        min-height: 800px;
        background: #fff;
        height: 100%;
        padding:3rem 15px;
        position: relative;
        margin-bottom: 1rem;
    }
}
.sticky-ads{
    display: none;
}

ul,h2{
    margin-bottom: 2rem;
}

.info-bar{
    position: absolute;;
    // margin: -3rem -15px 3rem -15px;
    background: #cccccc;
    width: 100%;
    left:15px;

    .category-drop-down{
        margin-top: 4px;
    }

    top:0;
        p{
        margin: 0;
        line-height: 2.2rem;
        font-size: 0.8rem;
        span{
            margin-right: 10px;
            padding-right: 10px
        }
    }
}


@include respond-above(md) {
    .main-content{
        .container{
            padding:3rem 1rem;
        }
    }
    .info-bar{
        .container{
            padding: 0 15px;
        }
    }
}
@include smallDesktopAndMobile{
    iframe{
        max-width: 100%;
        img{
            max-width: 100%;
        }
    }
    .top-add-container{
        max-width: 100%;
        min-height: 320px;
    }
   
}
@include largeDesktop {
    .top-add-container{
        // min-height: 240px;
        >div{
            // position: absolute;
            // left:0;
            // right: 0;
            // margin: auto;
            // iframe{
            //     margin-left:-200px;
            // }
        }
    }
    .outer-container{
        max-width: 980px;
        margin: 0 auto;
        background: #fff;
        position: relative;
        .main-inner-container{
            float: left;
            max-width: 980px;
            min-width: 980px;
        }
    }
    .sticky-ads{
        display: block;
        // width: 200px;
        position: absolute;
        left: 1000px;
        .secondAds{
            position: fixed;
            top:0;
        }
    }
    .side-ads{
        position: absolute;
        left: 1000px;
        >div{
            position: static;
            top:0;
        }
    }
  }

@include mobile{
    .sticky-ads,
    .side-ads{
        display: none;
    }
}


@media all and (max-width: 1229px) {
    .sticky-ads,
    .side-ads{
        display: none;
    }
}