@import '../style/constant.scss';
@import '../style/mixins.scss';
.weeekday{
    // @extend .grey-gradient;
    background: #747474 url(../images/new/hours_bg.gif) repeat-x;
    height: 30px;
    .container{
        position: relative;
    }
    ul{
        // padding:0 4rem;
        max-width: 85%;
        margin: auto;
        li {
            margin: 0 1.3rem;
            a{
                color: #fff;
                padding:.3rem .5rem;
            }
        }
        .active{
            background: rgb(32,124,202); /* Old browsers */
            background: -moz-linear-gradient(top, rgba(32,124,202,1) 0%, rgba(32,124,202,1) 12%, rgba(32,124,202,1) 12%, rgba(32,124,202,1) 20%, rgba(41,137,216,1) 44%, rgba(30,87,153,1) 91%, rgba(30,87,153,1) 91%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(32,124,202,1) 0%,rgba(32,124,202,1) 12%,rgba(32,124,202,1) 12%,rgba(32,124,202,1) 20%,rgba(41,137,216,1) 44%,rgba(30,87,153,1) 91%,rgba(30,87,153,1) 91%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(32,124,202,1) 0%,rgba(32,124,202,1) 12%,rgba(32,124,202,1) 12%,rgba(32,124,202,1) 20%,rgba(41,137,216,1) 44%,rgba(30,87,153,1) 91%,rgba(30,87,153,1) 91%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#207cca', endColorstr='#1e5799',GradientType=0 );
        }
        
    }
    .prev-link, .next-link{
        position: absolute;
        color: #fff;
        top:8px;
        // @extend .grey-gradient;
        background: #747474 url(../images/new/hours_bg.gif) repeat-x;
        padding: .3rem 1rem;
        margin-top: -0.5rem;
    }
    .prev-link{
        left:15px;
        
    }
    .next-link{
        right:15px;
    }
}

@include respond-below(md) {
    .weeekday{
        .container{
            position: relative;
            width: 100%;
            max-width: 100%;
            overflow-y: auto;
            
        }
        .prev-link{
            left:10px;
            
        }
        .next-link{
            right:10px;
        }
         ul{
            min-width: 940px;
            padding: 0 3rem;
        }
        .scroll-nav{
            width: 100%;
            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none; 
            &::-webkit-scrollbar {
                display: none;
              }
              
        }
    }
  }

  @include respond-below(xs){   
    .weeekday ul {
        min-width: 1600px;
        padding: 0 6rem;
        li{
            min-width: 125px;
            text-align: center;
            margin: 0 1rem;
            display: none;
            &.active{
                display: block;
            }
        }
    }
}
// @include largeDesktop {
//     .weeekday ul li {
//         margin: 0 1.5rem;
//     }
// }