@import '../../style/constant';
@import '../../style/mixins';

.user-menu {
  @extend .grey-gradient;

  .navbar {
    color: #fff;
    justify-content: center;
    a {
      color: #fff;
      text-decoration: none;
      display: inline-block;
      line-height: 30px;
      margin: 5px 0;
      padding: 0 1.5rem;
      border-left: 2px solid #fff
    }
    a:first-child {
      border: 0;
    }
  }
}

.user-container {
  max-width: 800px;
  margin: 0 auto;
  .my-list {
    background: none;
    border: 1px solid #ccc;
    ul li {
      padding: 10px 0;
    }
    ul li .name {
      color: #000;
    }
    h2 {
      color: #000;
      border-bottom: 1px solid #000;
      .print-icon {
        a {
          color: #000;
        }

      }
    }
  }
}

.toggle-btn {
  min-width: 50px;
}

.list-container {
  max-width: 800px;
  margin: 0 auto;
  p {
    color: #7b7979;
  }
  .add-to-list {
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
  ul {
    list-style: none;
    margin: 0 0 20px;
    padding: 0;
    li {
      list-style: none;
      padding: 10px;
      background: #f0eeee;
      margin-bottom: 3px;
      width: 100%;
      border-radius: 5px;
      .name {
        color: #222;
        vertical-align: middle;
        margin-left: 10px;
        text-transform: capitalize;
      }
    }
    .tv-logo {
      margin-right: 10px;
      width: 30px;
      display: inline-block;
    }
    .actions {
      float: right;

      .goto-top {
        margin: 0 10px;
        cursor: pointer;
        position: relative;
        .tool-tip {
          position: absolute;
          background: rgba(0, 0, 0, 0.7);
          color: #fff;
          font-size: 1rem;
          border-radius: 5px;
          top: 30px;
          &::before {
            content: "";

          }
        }
      }
      .goto-bottom{
        cursor: pointer;
      }
    }
  }
}

.channel-logo {
  margin: 0 5px;
  vertical-align: middle;
  max-width: 60px;
  display:inline-block;
  img{
    max-width:100%;
  }
}

.setting-page {
  .form {
    .row {
      margin-bottom: 2em;
    }
  }
}

.add-to-list-modal {
  .modal-header {
    border-bottom: 1px solid #e8e9eb;
    box-shadow: 0 2px 7px rgba(119, 125, 136, .14);
    padding: 1rem;
  }
  .modal-dialog {
    min-width: 90%;
  }
  .modal-title {
    width: 100%;
    h5, svg {
      float: left;
    }
    svg {
      margin-top: 5px;
    }
    h5 {
      margin: 0 1rem;
      line-height: 2rem;
      font-size: 1rem;
    }
    .search-active {
      width: calc(100% - 256px);
      margin: -6px 0;
    }
    .search {
      margin-top: 0;
    }
    .search .icon {

      svg {
        margin-top: 12px
      }
    }
  }
  .top {
    padding: 1rem 0;
    .reset {
      float: right;
      color: #007bff;
      cursor: pointer;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      padding: 5px 0;
      list-style: none;
      line-height: 24px;
      margin-bottom: 5px;
    }

  }
  .actions {
    float: right;
  }
}

@include respond-above(md) {
  .add-to-list-modal {
    .modal-dialog {
      min-width: 700px;
    }
  }
}

@include respond-below(md) {
  .user-menu {
    .navbar {
      a {
        padding: 0 .4rem;
      }
    }
  }

}

@include respond-below(xs) {
  h3 {
    font-size: 1.2rem;
  }
}