@import '../style/constant.scss';
@import '../style/mixins';

.program-list {
  margin: -1rem 0 1rem -0.3rem;
  padding: 0;
  list-style: none;
  &.first-section {
    margin-top: 3rem;
    li.item{
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4){
        min-height: 300px;
      }
    }
    
  }
  .top-logo {
    display: flex;
    margin-bottom: 10px;
    position: absolute;
    top: -2rem;
    left: 0;
    img{
      max-height: 40px;
      margin-top: -5px;
      max-width:100%;
    }
    h2{
      font-size: 14px;
      a {
        color: #575757;
        font-weight: 900;
        font-size: 14px;
        text-decoration: none;
      }
    }
    span {
      margin: 0 5px;
    }
    .name {
      color: #575757;
      font-weight: 900;
    }
  }
  .item {
    color: #fff;
    font-size: 0.8rem;
    padding: 0;
    background: #575757;
    border-radius: 10px;
    position: relative;
    margin: 0 2px 4rem 2px;
    width: calc(25% - 4px);
    max-width: calc(25% - 4px);
    min-height: 120px;
    overflow: visible;
    ul {
      padding: 10px 0.2rem;
      list-style: none;
      li {
        cursor: pointer;
        font-size: 11px;
        h3{
          font-size: 11px;
          font-weight: 400;
          color: #fff;
        }
        &.inactive {
          .program {
            color: #9b9b9b;
          }
        }
        &.viewAll {
          text-decoration: underline;
          font-weight: 700;
        }
        &.active {
          .program {
            color: #4fd0ec;
            font-weight: 900;
          }
        }
      }
      .time {
        color: #b4b4b4;
        display: inline-block;
        vertical-align: middle;
      }
      .status {
        color: #ed7635;
        display: inline-block;
        vertical-align: middle;
      }
      .program {
        display: inline-block;
        max-width: calc(100% - 51px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }
  }
}

.grid-add-container {
  margin: -3rem 0 5rem -15px;
  // #cncpt-lb1{
  //     max-width: 100%;

  // }
  // iframe{
  //     max-width: 100%;
  // }

}

@include tablet {
  .program-list {
    .item {
      width: calc(33.333% - 12px);
      max-width: calc(33.333% - 12px);
    }
  }
}

@include respond-below(sm) {
  .program-list {
    .item {
      width: calc(33.333% - 12px);
      max-width: calc(33.333% - 12px);
    }
  }
  .navbar {
    justify-content: flex-end;
  }
}

@include mobile {
.grid-add-container {
  min-height: 320px;
  margin-left: 0;
}
}

@include respond-below(xs) {
  .program-list {
    .item {
      width: calc(50% - 12px);
      max-width: calc(50% - 12px);
      .top-logo {
        top: -3rem
      }
    }
  }

}